<script setup>
import { useSlots, watch } from "vue";
import { sleep } from "@Res/js/helpers";

const props = defineProps({
    show: Boolean,
    content: String,
    time: {
        type: [String, Number],
        required: false,
        default: null,
    },
    placement: {
        type: String,
        default: "top",
        validator(value) {
            return ["top", "bottom", "left", "right"].includes(value);
        },
    },
});

const emit = defineEmits(["timeout"]);
const slots = useSlots();

watch(
    props,
    async () => {
        if (!props.show) return;

        await sleep(props.time);

        emit("timeout");
    },
    { deep: true }
);
</script>

<template>
    <Popper
        :content="content"
        :placement="placement"
        :show="show"
        arrow
        class="!border-none !m-0"
    >
        <slot />
        <template v-if="!!slots.content" #content="props">
            <slot name="content" v-bind="props" />
        </template>
    </Popper>
</template>

<style scoped>
:deep(.popper) {
    @apply bg-gray-800 dark:bg-gray-100 text-white dark:text-gray-800;
    @apply text-sm rounded-xl py-1.5 px-2 z-[60];
}

:deep(.popper #arrow::before) {
    @apply bg-gray-800 dark:bg-gray-100;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
    @apply bg-gray-800 dark:bg-gray-100;
}
</style>
